import { message } from 'antd'
import { debounce, memoize } from 'lodash'

// Factory functio to return a debounced caller with the message curried in
// so it can be used as the memoize key
const createDebouncedFunction = (fn: (arg: any) => any) => (msg: string) =>
  debounce(() => fn(msg), 8000, { leading: true })

// Memoized wrapper creates a single instance of the debounced caller for each message string
const memoizedError = memoize(createDebouncedFunction(message.error))
const memoizedInfo = memoize(createDebouncedFunction(message.info))
const memoizedWarning = memoize(createDebouncedFunction(message.warning))
const memoizedSuccess = memoize(createDebouncedFunction(message.success))

export const debouncedMessage = {
  error: (msg: string) => memoizedError(msg)(),
  info: (msg: string) => memoizedInfo(msg)(),
  warning: (msg: string) => memoizedWarning(msg)(),
  success: (msg: string) => memoizedSuccess(msg)(),
}
