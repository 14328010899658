import { VehicleRewardType } from 'gql/generated/graphql'

export enum SchemeId {
  // Referenced from:
  // https://github.com/team-blaze/basis-utils/blob/master/basis_utils/scheme/scheme_mapping.py
  secret_cycles = '1',
  london = '2',
  bcp = '3',
  barnet = '4',
  hereford = '5',
  raf = '6',
  hackney_council = '7',
  norwich = '8',
  watford = '9',
  staten_island = '10',
  tfl_smartbike = '11',
  isle_of_wight = '12',
  west_midlands = '13',
  london_cargo_bike = '14',
  greater_manchester = '15',
  wool = '16',
  cornwall = '19',
  portsmouth = '20',
  southampton = '21',
  hertsmere = '22',
  plymouth = '23',
  westminster_cargo_bike = '24',
  brighton = '25',
  leeds = '26',
  worcester = '27',
  stevenage = '28',
  guildford = '29',
  weymouth = '30',
  eastleigh = '31',
}

export const DockedSchemes: SchemeId[] = [
  SchemeId.brighton,
  SchemeId.greater_manchester,
  SchemeId.leeds,
  SchemeId.portsmouth,
  SchemeId.secret_cycles,
  SchemeId.southampton,
  SchemeId.west_midlands,
]

export const LockConfigurationSchemes: SchemeId[] = [
  SchemeId.brighton,
  SchemeId.greater_manchester,
  SchemeId.guildford,
  SchemeId.leeds,
  SchemeId.secret_cycles,
  SchemeId.stevenage,
]

export const SEGWAY_SCHEMES: SchemeId[] = [
  SchemeId.cornwall,
  SchemeId.watford,
  SchemeId.weymouth,
]

export const PromotionalZoneSchemes: SchemeId[] = [
  SchemeId.bcp,
  SchemeId.isle_of_wight,
  SchemeId.norwich,
  SchemeId.plymouth,
  SchemeId.worcester,
]

type RewardNames = Record<VehicleRewardType, string>
export const REWARD_TYPES: RewardNames = {
  [VehicleRewardType.rescue_stranded]: 'Stranded vehicle rescue',
  [VehicleRewardType.secure_front_lock]: 'Use front lock',
}

export const SCHEME_EMOJIS: Record<SchemeId, string> = {
  [SchemeId.secret_cycles]: '🤫',
  [SchemeId.london]: '🏰',
  [SchemeId.bcp]: '🏖️',
  [SchemeId.barnet]: '💇',
  [SchemeId.hereford]: '🍏',
  [SchemeId.raf]: '🛩️',
  [SchemeId.hackney_council]: '☕️',
  [SchemeId.norwich]: '🚜',
  [SchemeId.watford]: '⚽️',
  [SchemeId.staten_island]: '🗽',
  [SchemeId.tfl_smartbike]: '🚌',
  [SchemeId.isle_of_wight]: '🏝️',
  [SchemeId.west_midlands]: '🍫',
  [SchemeId.london_cargo_bike]: '📦',
  [SchemeId.greater_manchester]: '🐝',
  [SchemeId.wool]: '🐑',
  [SchemeId.cornwall]: '🥟',
  [SchemeId.portsmouth]: '⚓️',
  [SchemeId.southampton]: '🚢',
  [SchemeId.hertsmere]: '🦌',
  [SchemeId.plymouth]: '⛵️',
  [SchemeId.westminster_cargo_bike]: '🏛️',
  [SchemeId.brighton]: '🗼',
  [SchemeId.leeds]: '🦉',
  [SchemeId.worcester]: '🥫',
  [SchemeId.stevenage]: '🏫',
  [SchemeId.guildford]: '💼',
  [SchemeId.weymouth]: '🦕',
  [SchemeId.eastleigh]: '🦢',
}

// Old schemes still in DB but hide from Dashboard
export const LEGACY_SCHEMES: SchemeId[] = [
  SchemeId.barnet,
  SchemeId.raf,
  SchemeId.staten_island,
]

export const DEVICE_WARNING_COUNT = 1
