import { Tag, Typography } from 'antd'
import type { ColumnProps } from 'antd/lib/table'

import { Box } from '@weareberyl/design-system'

import Table from 'components/Table'

import {
  JOURNEY_SUMMARY_journey_confirmed_rewards,
  JOURNEY_SUMMARY_journey_possible_rewards,
} from './__generated__/JOURNEY_SUMMARY'
import { VehicleRewardType } from '__generated__/globalTypes'

import { REWARD_TYPES } from 'constants/domain'

type JourneyRewardProps = {
  journeyHistoryReady: boolean
  journeyErrored: boolean
  possibleRewards: JOURNEY_SUMMARY_journey_possible_rewards[]
  confirmedRewards: JOURNEY_SUMMARY_journey_confirmed_rewards[]
}

enum JourneyRewardStatus {
  pending = 'pending',
  forfeited = 'forfeited',
  error = 'error',
  confirmed = 'confirmed',
}

const REWARD_STATUS_INFO = {
  [JourneyRewardStatus.pending]: {
    status: 'Pending',
    description:
      'The user has hired a vehicle that may qualify for this reward. Check back when the journey is complete',
    color: 'orange',
  },
  [JourneyRewardStatus.forfeited]: {
    status: 'Forfeited',
    description:
      'The user did not take the required action to qualify for this reward, resulting in a forfeit',
    color: 'red',
  },
  [JourneyRewardStatus.error]: {
    status: 'Error',
    description:
      'There was an error with the journey. Unable to process this reward',
    color: 'gray',
  },
  [JourneyRewardStatus.confirmed]: {
    status: 'Eligible',
    description:
      "The user is eligible for this reward. They may receive a Beryl Bonus - check the user's products to confirm",
    color: 'green',
  },
}

const getRewardStatus = (
  reward: VehicleRewardType,
  confirmedRewardsTypes: VehicleRewardType[],
  journeyHistoryReady: boolean,
  journeyErrored: boolean,
): JourneyRewardStatus => {
  if (confirmedRewardsTypes.includes(reward)) {
    return JourneyRewardStatus.confirmed
  } else if (journeyErrored) {
    return JourneyRewardStatus.error
  } else if (!journeyHistoryReady) {
    return JourneyRewardStatus.pending
  }
  return JourneyRewardStatus.forfeited
}

export const JourneyRewards = ({
  journeyHistoryReady,
  journeyErrored,
  possibleRewards,
  confirmedRewards,
}: JourneyRewardProps) => {
  const possibleRewardsTypes = possibleRewards.map(reward => reward.type)
  const confirmedRewardsTypes = confirmedRewards.map(reward => reward.type)

  const rewardData = possibleRewardsTypes.map((reward, index) => {
    const rewardStatus = getRewardStatus(
      reward,
      confirmedRewardsTypes,
      journeyHistoryReady,
      journeyErrored,
    )
    const rewardType = REWARD_TYPES[reward]
    const { status, description, color } = REWARD_STATUS_INFO[rewardStatus]
    return {
      reward: rewardType,
      status: <Tag color={color}>{status}</Tag>,
      explanation: description,
      id: index.toString(),
    }
  })

  const columns: ColumnProps<unknown>[] = [
    {
      title: 'Reward',
      dataIndex: 'reward',
      key: 'reward',
      width: 'auto',
      render: (reward: string) => (
        <Typography.Text ellipsis>{reward}</Typography.Text>
      ),
    },
    { title: 'Status', dataIndex: 'status', key: 'status' },
    { title: 'Explanation', dataIndex: 'explanation', key: 'explanation' },
  ]

  return (
    <Box mt={4}>
      <Table
        id="journey-rewards-table"
        hideProgress
        columns={columns}
        size="small"
        data={{
          table: {
            pagination: false,
            nodes: rewardData,
          },
        }}
      />
    </Box>
  )
}
